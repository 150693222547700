
export default {
    enterpriseName: "Hotel Binder Quality Inn",
    address: {
        street: {
            pt_br: "Rua Doutor Deodato Wertheimer, 1413",
            en_us: "1413, Doutor Deodato Wertheimer street",
            es_es: "Calle Doutor Deodato Wertheimer, 1413"
        },
        city: "Mogi das Cruzes, SP",
        country: {
            pt_br: "Brasil",
            en_us: "Brazil",
            es_es: "Brasil"
        },
        googleMapsLink: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.2497155935584!2d-46.197035885762915!3d-23.523519284701685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cdd810a42fb1db%3A0x88829cfc4f19680e!2sBinder%20Hotel!5e0!3m2!1spt-BR!2sbr!4v1663987182159!5m2!1spt-BR!2sbr`
    },
    contact: {
        email: {
            icon: "fas fa-envelope",
            value: "reservas@binderhotel.com.br",
            text: {
                pt_br: "E-mail",
                en_us: "E-mail",
                es_es: "E-mail"
            }
        },
        phone: {
            icon: "fas fa-phone-alt",
            value: "(11) 4799-6611",
            text: {
                pt_br: "Telefone",
                en_us: "Phone",
                es_es: "Teléfono"
            }
        },
        onlineReservation: {
            link: "https://api.whatsapp.com/send?phone=551147996611",
            text: {
                pt_br: "Reservas Online",
                en_us: "Online Reservations",
                es_es: "Reservas Online"
            }
        }
    },
    socialLinks: [
        {
            name: "WhatsApp",
            icon: "fab fa-whatsapp",
            link: "https://api.whatsapp.com/send?phone=551147996611",
            toShow: true,
            toShowOnFloatingActionBar: true
        }, {
            name: "Telegram",
            icon: "fab fa-telegram-plane",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Instagram",
            icon: "fab fa-instagram",
            link: "https://instagram.com/hotelbindermc?igshid=YmMyMTA2M2Y=",
            toShow: true,
            toShowOnFloatingActionBar: true
        }, {
            name: "Facebook",
            icon: "fab fa-facebook-f",
            link: "https://m.facebook.com/Hotel-Binder-102657709138838/",
            toShow: true,
            toShowOnFloatingActionBar: false
        }, {
            name: "Facebook Messenger",
            icon: "fab fa-messenger-f",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Twitter",
            icon: "fab fa-twitter",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Skype",
            icon: "fab fa-skype",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "LinkedIn",
            icon: "fab fa-linkedin",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "YouTube",
            icon: "fab fa-youtube",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }
    ],
    developer: {
        link: "https://www.linkedin.com/in/reginaldo-savian-jr-045820141/",
        name: {
            pt_br: "SavX - Desenvolvimento de Sistemas",
            en_us: "SavX - Systems Development",
            es_es: "SavX - Desarrollo de Sistemas"
        },
        text: {
            pt_br: "Desenvolvido por",
            en_us: "Developed by",
            es_es: "Desarrollado por"
        }
    }
}
