
import dianoiva_1 from '../assets/img/specials/dianoiva_1.jpg';
import dianoiva_2 from '../assets/img/specials/dianoiva_2.jpg';
import dianoiva_3 from '../assets/img/specials/dianoiva_3.jpg';
import dianoiva_4 from '../assets/img/specials/dianoiva_4.jpg';
import dianoiva_5 from '../assets/img/specials/dianoiva_5.jpg';
import dianoiva_6 from '../assets/img/specials/dianoiva_6.jpg';
import dianoiva_7 from '../assets/img/specials/dianoiva_7.jpg';
import dianoiva_8 from '../assets/img/specials/dianoiva_8.jpg';
import dianoiva_9 from '../assets/img/specials/dianoiva_9.jpg';
import dianoiva_10 from '../assets/img/specials/dianoiva_10.jpg';


import breakfast_1 from '../assets/img/specials/breakfast_1.jpg';
import breakfast_2 from '../assets/img/specials/breakfast_2.jpg';
import breakfast_3 from '../assets/img/specials/breakfast_3.jpg';
import breakfast_4 from '../assets/img/specials/breakfast_4.jpg';
import breakfast_5 from '../assets/img/specials/breakfast_5.jpg';
import breakfast_6 from '../assets/img/specials/breakfast_6.jpg';
import breakfast_7 from '../assets/img/specials/breakfast_7.jpg';
import breakfast_8 from '../assets/img/specials/breakfast_8.jpg';
import breakfast_9 from '../assets/img/specials/breakfast_9.jpg';
import breakfast_10 from '../assets/img/specials/breakfast_10.jpg';
import breakfast_11 from '../assets/img/specials/breakfast_11.jpg';
import breakfast_12 from '../assets/img/specials/breakfast_12.jpg';
import breakfast_13 from '../assets/img/specials/breakfast_13.jpg';
import breakfast_14 from '../assets/img/specials/breakfast_14.jpg';
import breakfast_15 from '../assets/img/specials/breakfast_15.jpg';
import breakfast_16 from '../assets/img/specials/breakfast_16.jpg';
import breakfast_17 from '../assets/img/specials/breakfast_17.jpg';
import breakfast_18 from '../assets/img/specials/breakfast_18.jpg';



export default [
    {
        images: [
            dianoiva_1,
            dianoiva_2,
            dianoiva_3,
            dianoiva_4,
            dianoiva_5,
            dianoiva_6,
            dianoiva_7,
            dianoiva_8,
            dianoiva_9,
            dianoiva_10
        ],
        name: {
            pt_br: "Dia da Noiva",
            en_us: "Wife's day",
            es_es: "Día de la Esposa"
        },
        description: {
            pt_br: "Se preparar para o grande dia com melhor conforto e elegância no Hotel Binder com a acomodação completa para a noiva e madrinhas, podendo receber profissionais como maquiador, cabelereiro e fotógrafos. Tudo para deixar o momento perfeito para o grande o dia",
            en_us: "Get ready for the big day with the best comfort and elegance at the Hotel Binder with complete accommodation for the bride and bridesmaids, being able to receive professionals such as makeup artist, hairdresser and photographers. Everything to leave the perfect moment for the big day",
            es_es: "Prepárate para el gran día con la mejor comodidad y elegancia en el Hotel Binder con alojamiento completo para la novia y las damas de honor, pudiendo recibir a profesionales como maquilladores, peluqueros y fotógrafos. Todo para dejar el momento perfecto para el gran día"
        }
    }, {
        images: [
            breakfast_1,
            breakfast_2,
            breakfast_3,
            breakfast_4,
            breakfast_5,
            breakfast_6,
            breakfast_7,
            breakfast_8,
            breakfast_9,
            breakfast_10,
            breakfast_11,
            breakfast_12,
            breakfast_13,
            breakfast_14,
            breakfast_15,
            breakfast_16,
            breakfast_17,
            breakfast_18,
        ],
        name: {
            pt_br: "Breakfast",
            en_us: "Breakfast",
            es_es: "Desayuno"
        },
        description: {
            pt_br: "No Binder tanto hóspedes quanto não hóspedes podem desfrutar do nosso café da manhã, mais de 40 itens preparados pela nossa equipe de gastronomia. Um dos melhores cafés de Mogi das Cruzes pronto para te receber com um café especial e sair da rotina de uma forma deliciosa",
            en_us: "At Binder both guests and non-guests can enjoy our breakfast, more than 40 items prepared by our gastronomy team. One of the best cafes in Mogi das Cruzes ready to welcome you with a special coffee and get out of the routine in a delicious way",
            es_es: "En Binder tanto huéspedes como no huéspedes pueden disfrutar de nuestros desayunos, más de 40 platos elaborados por nuestro equipo de gastronomía. Una de las mejores cafeterías de Mogi das Cruzes lista para recibirte con un café especial y salir de la rutina de una manera deliciosa"
        }
    }
];