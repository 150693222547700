import { useState } from "react";
import Slider from '../../components/Slider/Slider';

const SpecialsModal = ({
    id,
    special,
    currentLanguage
}) => {
    const [activedSlider, setActivedSlider] = useState(false);
    const [sliderIndex, setSliderIndex] = useState(0);

    const handleSliderChange = (actived, index = 0) => {
        setActivedSlider(actived);

        if (index < 0) {
            index = special.images.length - 1;
        } else if (index > special.images.length - 1) {
            index = 0;
        }

        setSliderIndex(index);
    }

    return (
        <>
            <div class="modal fade" id={id} tabindex="-1" aria-labelledby="SpecialsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"> {special.name[currentLanguage]} </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <p>{special.description[currentLanguage]}</p>
                                </div>

                                <div className="col-12 image-list">
                                    {
                                        special.images.map((image, index) => {
                                            return (
                                                <div className="image-item" key={index} onClick={_ => handleSliderChange(true, index)}>
                                                    <img
                                                        src={image}
                                                        alt={`${special.name[currentLanguage]}_${index}`}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Slider actived={activedSlider} activeIndex={sliderIndex} imagesArray={special.images} currentLanguage={currentLanguage} handleSliderChange={handleSliderChange} />
        </>
    );
}


export default SpecialsModal;