
import maranata_1 from '../assets/img/events/maranata_1.jpg';
import maranata_2 from '../assets/img/events/maranata_2.jpg';
import maranata_3 from '../assets/img/events/maranata_3.jpg';


import adonai_1 from '../assets/img/events/adonai_1.jpg';
import adonai_2 from '../assets/img/events/adonai_2.jpg';
import adonai_3 from '../assets/img/events/adonai_3.jpg';
import adonai_4 from '../assets/img/events/adonai_4.jpg';


export default [
    {
        images: [
            maranata_1,
            maranata_2,
            maranata_3
        ],
        name: {
            pt_br: "Auditório Maranata",
            en_us: "Maranatha Auditorium",
            es_es: "Auditorio Maranata",
        },
        description: {
            pt_br: "Contamos com um auditório espaçoso, para eventos formais, cerimónias e festas de finais de ano empresarial e muito mais.",
            en_us: "We have a spacious auditorium for formal events, ceremonies and corporate year-end parties and much more.",
            es_es: "Contamos con un amplio auditorio para eventos formales, ceremonias y fiestas corporativas de fin de año y mucho más.",
        }
    }, {
        images: [
            adonai_1,
            adonai_2,
            adonai_3,
            adonai_4
        ],
        name: {
            pt_br: "Auditório Adonai",
            en_us: "Adonai Auditorium",
            es_es: "Auditorio Adonai",
        },
        description: {
            pt_br: "Contamos com um auditório espaçoso, para eventos formais, cerimónias e festas de finais de ano empresarial e muito mais.",
            en_us: "We have a spacious auditorium for formal events, ceremonies and corporate year-end parties and much more.",
            es_es: "Contamos con un amplio auditorio para eventos formales, ceremonias y fiestas corporativas de fin de año y mucho más.",
        }
    }
];