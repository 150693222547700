
import Constants from '../../data/Constants';
import Translations from '../../data/Translations';
import './style.css';

const Footer = ({currentLanguage}) => {
    const showIframeGoogleMaps = () => {
        if (Constants.address.googleMapsLink) {
            return (
                <iframe src={Constants.address.googleMapsLink} className="googlemaps" allowfullscreen="" loading="lazy"/>
            );
        }

        return false;
    }

    return (
        <>
            <section id="about" class="about section-bg">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>{Translations.about[currentLanguage]}</h2>
                        <p>{Translations.aboutTitle[currentLanguage]}</p>
                    </div>

                    <div class="row content">
                        <div class="col-lg-12">
                            <p>
                                {
                                    Translations.aboutDescription[currentLanguage].split("\n").map((item, index) => {
                                        return <p key={index}> {item} </p>
                                    })
                                }
                            </p>
                        </div>
                        
                        {
                            showIframeGoogleMaps()
                        }
                    </div>
                </div>
            </section>

            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-5 col-md-6">
                                <div className="footer-info">
                                    <h3>{Constants.enterpriseName}</h3>
                                    
                                    <p>
                                        {Constants.address.street[currentLanguage]} <br/>
                                        {Constants.address.city} <br/>
                                        {Constants.address.country[currentLanguage]} <br/> <br/>
                                        
                                        <strong>{Constants.contact.phone.text[currentLanguage]}:</strong> {Constants.contact.phone.value} <br/>
                                        <strong>{Constants.contact.email.text[currentLanguage]}:</strong> {Constants.contact.email.value}<br/>
                                    </p>

                                    <div className="social-links mt-3">
                                        {
                                            Constants.socialLinks
                                                .filter(item => item.toShow)
                                                .map((item, index) => {
                                                    return (
                                                        <a key={index} href={item.link} target="_blank" title={item.name} className={item.name.toLowerCase()}>
                                                            <i className={item.icon}></i>
                                                        </a>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2" />

                            <div className="col-lg-5 col-md-6 footer-links">
                                <h4>{Translations.internalLinks[currentLanguage]}</h4>
                                <ul>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#topbar">{Translations.home[currentLanguage]}</a></li>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#services">{Translations.services[currentLanguage]}</a></li>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#bedrooms">{Translations.accommodations[currentLanguage]}</a></li>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#events">{Translations.events[currentLanguage]}</a></li>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#specials">{Translations.specials[currentLanguage]}</a></li>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#gallery">{Translations.gallery[currentLanguage]}</a></li>
                                    <li><i class="fas fa-chevron-right"></i> <a href="#about">{Translations.about[currentLanguage]}</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div class="copyright">
                        <strong>{Constants.enterpriseName}</strong>
                        <br/> <br/>
                        {Translations.copyright[currentLanguage]}
                    </div>

                    <br/>
                    
                    <div className='copyright mt-1'>
                        {Constants.developer.text[currentLanguage]} <a href={Constants.developer.link} target="_blank" rel="noreferrer">{Constants.developer.name[currentLanguage]}</a>
                    </div>
                </div>
            </footer>
        </>
    );
}


export default Footer;