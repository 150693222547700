
import './style.css';

import Constants from '../../data/Constants';
import Translations from '../../data/Translations';
import LanguagesTrigger from '../Languages/LanguagesTrigger';
import { useState } from 'react';
import LogoHeader from '../../assets/img/full-logo.png';

const Header = ({currentLanguage}) => {
    const [isNavbarMobile, setIsNavbarMobile] = useState(false);

    return (
        <>
            <section id="topbar" />

            <header id="header">
                <div className="container d-flex align-items-center justify-content-between">

                    <a href="/" className="logo"><img src={LogoHeader} alt="" className="img-fluid"/></a>

                    <nav id="navbar" className={`navbar ${isNavbarMobile ? 'navbar-mobile' : ''}`}>
                        <ul>
                        <li><a className="nav-link scrollto" href="#topbar" onClick={_ => setIsNavbarMobile(false)}> {Translations.home[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#services" onClick={_ => setIsNavbarMobile(false)}> {Translations.services[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#bedrooms" onClick={_ => setIsNavbarMobile(false)}> {Translations.accommodations[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#events" onClick={_ => setIsNavbarMobile(false)}> {Translations.events[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#specials" onClick={_ => setIsNavbarMobile(false)}> {Translations.specials[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#gallery" onClick={_ => setIsNavbarMobile(false)}> {Translations.gallery[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto " href="#about" onClick={_ => setIsNavbarMobile(false)}> {Translations.about[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto active onlineReservation small-pulse-animation animation-fast-duration" href={Constants.contact.onlineReservation.link} target="_blank" onClick={_ => setIsNavbarMobile(false)}> {Constants.contact.onlineReservation.text[currentLanguage]} </a></li>
                        </ul>

                        <LanguagesTrigger currentLanguage={currentLanguage} />

                        <i className={`mobile-nav-toggle ${isNavbarMobile ? 'actived fas fa-times' : 'fas fa-bars'}`} onClick={_ => setIsNavbarMobile(!isNavbarMobile)}></i>
                    </nav>
                </div>
            </header>
        </>
    );
}


export default Header;