
import casal_1 from '../assets/img/bedrooms/casal_1.jpg';
import casal_2 from '../assets/img/bedrooms/casal_2.jpg';
import casal_3 from '../assets/img/bedrooms/casal_3.jpg';
import casal_4 from '../assets/img/bedrooms/casal_4.jpg';
import casal_5 from '../assets/img/bedrooms/casal_5.jpg';
import casal_6 from '../assets/img/bedrooms/casal_6.jpg';
import casal_7 from '../assets/img/bedrooms/casal_7.jpg';
import casal_8 from '../assets/img/bedrooms/casal_8.jpg';
import casal_9 from '../assets/img/bedrooms/casal_9.jpg';


import solteiroduplo_1 from '../assets/img/bedrooms/solteiroduplo_1.jpg';
import solteiroduplo_2 from '../assets/img/bedrooms/solteiroduplo_2.jpg';
import solteiroduplo_3 from '../assets/img/bedrooms/solteiroduplo_3.jpg';
import solteiroduplo_4 from '../assets/img/bedrooms/solteiroduplo_4.jpg';
import solteiroduplo_5 from '../assets/img/bedrooms/solteiroduplo_5.jpg';
import solteiroduplo_6 from '../assets/img/bedrooms/solteiroduplo_6.jpg';
import solteiroduplo_7 from '../assets/img/bedrooms/solteiroduplo_7.jpg';
import solteiroduplo_8 from '../assets/img/bedrooms/solteiroduplo_8.jpg';
import solteiroduplo_9 from '../assets/img/bedrooms/solteiroduplo_9.jpg';
import solteiroduplo_10 from '../assets/img/bedrooms/solteiroduplo_10.jpg';
import solteiroduplo_11 from '../assets/img/bedrooms/solteiroduplo_11.jpg';


import solteirotriplo_1 from '../assets/img/bedrooms/solteirotriplo_1.jpg';
import solteirotriplo_2 from '../assets/img/bedrooms/solteirotriplo_2.jpg';
import solteirotriplo_3 from '../assets/img/bedrooms/solteirotriplo_3.jpg';
import solteirotriplo_4 from '../assets/img/bedrooms/solteirotriplo_4.jpg';
import solteirotriplo_5 from '../assets/img/bedrooms/solteirotriplo_5.jpg';


import suitemaster_1 from '../assets/img/bedrooms/suitemaster_1.jpg';
import suitemaster_2 from '../assets/img/bedrooms/suitemaster_2.jpg';
import suitemaster_3 from '../assets/img/bedrooms/suitemaster_3.jpg';
import suitemaster_4 from '../assets/img/bedrooms/suitemaster_4.jpg';
import suitemaster_5 from '../assets/img/bedrooms/suitemaster_5.jpg';
import suitemaster_6 from '../assets/img/bedrooms/suitemaster_6.jpg';
import suitemaster_7 from '../assets/img/bedrooms/suitemaster_7.jpg';


import suitenupcias_1 from '../assets/img/bedrooms/suitenupcias_1.jpg';
import suitenupcias_2 from '../assets/img/bedrooms/suitenupcias_2.jpg';
import suitenupcias_3 from '../assets/img/bedrooms/suitenupcias_3.jpg';
import suitenupcias_4 from '../assets/img/bedrooms/suitenupcias_4.jpg';
import suitenupcias_5 from '../assets/img/bedrooms/suitenupcias_5.jpg';
import suitenupcias_6 from '../assets/img/bedrooms/suitenupcias_6.jpg';
import suitenupcias_7 from '../assets/img/bedrooms/suitenupcias_7.jpg';
import suitenupcias_8 from '../assets/img/bedrooms/suitenupcias_8.jpg';
import suitenupcias_9 from '../assets/img/bedrooms/suitenupcias_9.jpg';
import suitenupcias_10 from '../assets/img/bedrooms/suitenupcias_10.jpg';


import familia_1 from '../assets/img/bedrooms/familia_1.jpg';
import familia_2 from '../assets/img/bedrooms/familia_2.jpg';
import familia_3 from '../assets/img/bedrooms/familia_3.jpg';



export default [
    {
        images: [
            casal_1,
            casal_2,
            casal_3,
            casal_4,
            casal_5,
            casal_6,
            casal_7,
            casal_8,
            casal_9
        ],
        name: {
            pt_br: "Casal",
            en_us: "Couple",
            es_es: "Pareja"
        },
        description: {
            pt_br: "Possui uma cama de casal, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho e minibar",
            en_us: "It has a double bed, cable TV, air conditioning, free wi-fi, work desk and minibar",
            es_es: "Cuenta con cama doble, TV por cable, aire acondicionado, wi-fi gratis, mesa de trabajo y minibar",
        }
    }, {
        images: [
            solteiroduplo_1,
            solteiroduplo_2,
            solteiroduplo_3,
            solteiroduplo_4,
            solteiroduplo_5,
            solteiroduplo_6,
            solteiroduplo_7,
            solteiroduplo_8,
            solteiroduplo_9,
            solteiroduplo_10,
            solteiroduplo_11
        ],
        name: {
            pt_br: "Solteiro Duplo",
            en_us: "Double Single",
            es_es: "Doble Individual",
        },
        description: {
            pt_br: "Possui duas camas de solteiro, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho e minibar",
            en_us: "It has two single beds, cable TV, air conditioning, free wi-fi, work desk and minibar",
            es_es: "Cuenta con dos camas individuales, TV por cable, aire acondicionado, wi-fi gratuito, mesa de trabajo y minibar",
        }
    }, {
        images: [
            solteirotriplo_1,
            solteirotriplo_2,
            solteirotriplo_3,
            solteirotriplo_4,
            solteirotriplo_5
        ],
        name: {
            pt_br: "Solteiro Triplo",
            en_us: "Triple Single",
            es_es: "Triple Invididual"
        },
        description: {
            pt_br: "Possui três camas de solteiro, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho e minibar",
            en_us: "It has three single beds, cable TV, air conditioning, free wi-fi, work desk and minibar",
            es_es: "Cuenta con tres camas individuales, TV por cable, aire acondicionado, wi-fi gratuito, mesa de trabajo y minibar",
        }
    }, {
        images: [
            suitemaster_1,
            suitemaster_2,
            suitemaster_3,
            suitemaster_4,
            suitemaster_5,
            suitemaster_6,
            suitemaster_7
        ],
        name: {
            pt_br: "Suíte Master",
            en_us: "Master Suite",
            es_es: "Suite Principal",
        },
        description: {
            pt_br: "Possui uma cama de casal, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho, minibar e hidromassagem",
            en_us: "It has a double bed, cable TV, air conditioning, free wi-fi, work desk, minibar and whirlpool",
            es_es: "Cuenta con una cama doble, TV por cable, aire acondicionado, wi-fi gratuito, mesa de trabajo, minibar e hidromasaje",
        }
    }, {
        images: [
            suitenupcias_1,
            suitenupcias_2,
            suitenupcias_3,
            suitenupcias_4,
            suitenupcias_5,
            suitenupcias_6,
            suitenupcias_7,
            suitenupcias_8,
            suitenupcias_9,
            suitenupcias_10
        ],
        name: {
            pt_br: "Suíte Núpcias",
            en_us: "Nuptial Suite",
            es_es: "Suite Nupcial",
        },
        description: {
            pt_br: "Possui uma cama de casal, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho, minibar e hidromassagem.",
            en_us: "It has a double bed, cable TV, air conditioning, free wi-fi, work desk, minibar and whirlpool",
            es_es: "Cuenta con cama doble, TV por cable, aire acondicionado, wi-fi gratis, mesa de trabajo, minibar e hidromasaje",
        }
    }, {
        images: [
            familia_1,
            familia_2,
            familia_3
        ],
        name: {
            pt_br: "Quarto Família",
            en_us: "Family Room",
            es_es: "Cuarto familiar",
        },
        description: {
            pt_br: "Pode ser no formato com uma cama de casal e duas de solteiro ou então com quatro camas de solteiro, além de possuir TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho e minibar.",
            en_us: "It can be in the format with a double bed and two single beds or with four single beds, in addition to having cable TV, air conditioning, free wi-fi, desk and minibar",
            es_es: "Puede ser en el formato con cama doble y dos camas individuales o con cuatro camas individuales, además de contar con televisión por cable, aire acondicionado, wi-fi gratuito, escritorio y minibar",
        }
    }
];
