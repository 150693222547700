import './style.css';

import Carousel from '../../data/Carousel';
import { useEffect, useState } from 'react';


const Home = ({ currentLanguage }) => {
    const [enabledIntervalChange, setEnabledIntervalChange] = useState(true);
    const [carouselIndex, setCarouselIndex] = useState(0);

    
    useEffect(_ => {
        const loop = setInterval(() => {
            if (enabledIntervalChange) {
                handlerCarouselIndexChange(carouselIndex + 1);
            }
            
            setEnabledIntervalChange(true);
        }, 8000);
        
        return () => {
            clearInterval(loop);
        };
    });
    

    const handlerCarouselIndexChange = (index) => {
        if (index < 0) {
            index = Carousel.length - 1;
        } else if (index > Carousel.length - 1) {
            index = 0;
        }

        setCarouselIndex(index);
        setEnabledIntervalChange(false);
    }


    return (
        <>
            <section id="home">
                <div className="carousel slide carousel-fade">

                    <ol className="carousel-indicators" id="home-carousel-indicators">
                        {
                            Carousel.map((item, index) => {
                                return (
                                    <li key={index} className={`${carouselIndex == index ? 'active' : ''}`} onClick={_ => handlerCarouselIndexChange(index)} />
                                );
                            })
                        }
                    </ol>

                    <div className="carousel-inner" role="listbox">
                        {
                            Carousel.map((item, index) => {
                                return (
                                    <div className={`carousel-item ${carouselIndex == index ? 'active' : ''}`} key={index} style={{backgroundImage: `url(${item.image})`}}>
                                        <div className="carousel-container">
                                            <div className="container">
                                                {
                                                    item.title && (
                                                        <h2 className="title">{item.title[currentLanguage]}</h2>
                                                    )
                                                }
                                                
                                                {
                                                    item.subtitle && (
                                                        <p className="subtitle">{item.subtitle[currentLanguage]}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <a className="carousel-control-prev cursor-pointer" onClick={_ => handlerCarouselIndexChange(carouselIndex - 1)}>
                        <span className="carousel-control-prev-icon fas fa-chevron-left"></span>
                    </a>

                    <a className="carousel-control-next cursor-pointer" onClick={_ => handlerCarouselIndexChange(carouselIndex + 1)}>
                        <span className="carousel-control-next-icon fas fa-chevron-right"></span>
                    </a>

                </div>
            </section>
        </>
    )
}

export default Home;