import { useState } from "react";
import FloatingActionBar from "./components/FloatingActionBar/FloatingActionBar";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Loading from "./components/Loading/Loading";

import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";

import Constants from "./data/Constants";
import LanguagesModal from "./components/Languages/LanguagesModal";
import Gallery from "./pages/Gallery/Gallery";
import Bedrooms from "./pages/Bedrooms/Bedrooms";
import Events from "./pages/Events/Events";
import Specials from "./pages/Specials/Specials";


const nameLocalStorageLanguage = `${Constants.enterpriseName.toLowerCase().replace(/ /g, '')}_currentlanguage`;

const App = () => {
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem(nameLocalStorageLanguage) || "pt_br");

  const handleCurrentLanguageChange = (value) => {
    localStorage.setItem(nameLocalStorageLanguage, value);
    setCurrentLanguage(value);
  }

  return (
    <>
      <Loading />

      <Header currentLanguage={currentLanguage} handleCurrentLanguageChange={handleCurrentLanguageChange} />
      <Home currentLanguage={currentLanguage} />
      <Services currentLanguage={currentLanguage} />
      <Bedrooms currentLanguage={currentLanguage} />
      <Events currentLanguage={currentLanguage} />
      <Specials currentLanguage={currentLanguage} />
      <Gallery currentLanguage={currentLanguage} />
      <Footer currentLanguage={currentLanguage} />

      <LanguagesModal currentLanguage={currentLanguage} handleCurrentLanguageChange={handleCurrentLanguageChange} />
      <FloatingActionBar currentLanguage={currentLanguage} />
    </>
  );
}

export default App;
