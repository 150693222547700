
import './style.css';

import Slider from 'react-touch-drag-slider';

const MySlider = ({actived, activeIndex, imagesArray, currentLanguage, handleSliderChange}) => {
    
    document.body.style.overflow = actived ? 'hidden' : 'auto';

    if (actived) {
        return (
            <div className={`slider-background actived`}>
                <i className="fas fa-times close" onClick={_ => handleSliderChange(false, 0)} />
    
                <div className="controls">
                    <i className="fas fa-chevron-left" onClick={_ => handleSliderChange(true, activeIndex - 1)}/>
                    <i className="fas fa-chevron-right" onClick={_ => handleSliderChange(true, activeIndex + 1)} />
                </div>
    
                <Slider
                    onSlideComplete={(index) => {
                        handleSliderChange(true, index);
                    }}
                    activeIndex={activeIndex}
                    threshHold={100}
                    transition={0.5}
                    scaleOnDrag={true}
                >
                    {
                        imagesArray.map((item, index) => (
                            <img src={item.image || item} key={index} />
                        ))
                    }
                </Slider>
            </div>
        );
    }

    return false;
}


export default MySlider;