import Translations from '../../data/Translations';
import ServicesArray from '../../data/Services';
import './style.css';

const Services = ({ currentLanguage }) => {
    return (
        <>
            <section id="services" class="services">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>{Translations.services[currentLanguage]}</h2>
                        <p>{Translations.servicesTitle[currentLanguage]}</p>
                    </div>

                    <div class="row content">
                        <div class="col-lg-12">
                            <p>
                                {Translations.servicesDescription[currentLanguage]}
                            </p>
                        </div>
                    </div>

                    <div class="row services-row">
                        {
                            ServicesArray.map((service, index) => {
                                return (
                                    <div class="mt-3 mb-3 col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" key={index}>
                                        <div class="icon-box">
                                            <div class="icon"><i class={service.icon}></i></div>
                                            <h4><a>{service.title[currentLanguage]}</a></h4>
                                            <p>{service.description[currentLanguage]}</p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;