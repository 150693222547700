
import { useState } from 'react';
import './style.css';

const Loading = () => {
    const [isLoading, setIsLoading] = useState(false);

    window.onload = () => {
        setIsLoading(false);
    }

    return (
        <div className={`loading ${isLoading ? 'actived' : ''}`}>
            <div class="spinner-border text-light" role="status">
                <span class="visually-hidden"> </span>
            </div>
        </div>
    );
}

export default Loading;