
import './style.css';

import Translations from '../../data/Translations';
import BedroomsArray from '../../data/Bedrooms';
import LazyImage from '../../components/LazyImage/LazyImage';
import BedroomModal from './BedroomModal';

const Bedrooms = ({ currentLanguage }) => {
    return (
        <>
            <section id="bedrooms" class="bedrooms section-bg">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>{Translations.accommodations[currentLanguage]}</h2>
                        <p>{Translations.accommodationsTitle[currentLanguage]}</p>
                    </div>

                    <div class="row content">
                        <div class="col-lg-12">
                            <p>
                                {Translations.accommodationsDescription[currentLanguage]}
                            </p>
                        </div>
                    </div>

                    <div class="row" data-aos="fade-up" data-aos-delay="100">
                        {
                            BedroomsArray.map((item, index) => {
                                const id = `bedroom-${index}`;

                                return (
                                    <>
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <div
                                                className="member"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#${id}`}
                                            >
                                                <div className="pic">
                                                    <LazyImage
                                                        src={item.images[0]}
                                                        alt={item.name[currentLanguage]}
                                                    />
                                                </div>

                                                <div className="member-info">
                                                    <h4>{item.name[currentLanguage]}</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <BedroomModal
                                            bedroom={item}
                                            currentLanguage={currentLanguage}
                                            id={id}
                                        />
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bedrooms;