
import academia_1 from '../assets/img/gallery/academia_1.jpg';
import academia_2 from '../assets/img/gallery/academia_2.jpg';
import academia_3 from '../assets/img/gallery/academia_3.jpg';
import academia_4 from '../assets/img/gallery/academia_4.jpg';


import interno_1 from '../assets/img/gallery/interno_1.jpg';
import interno_2 from '../assets/img/gallery/interno_2.jpg';
import interno_3 from '../assets/img/gallery/interno_3.jpg';
import interno_4 from '../assets/img/gallery/interno_4.jpg';
import interno_5 from '../assets/img/gallery/interno_5.jpg';


export default [
    {
        images: [
            academia_1,
            academia_2,
            academia_3,
            academia_4,
            interno_1,
            interno_2,
            interno_3,
            interno_4,
            interno_5
        ],
        name: {
            pt_br: "Galeria",
            en_us: "Gallery",
            es_es: "Galería"
        }, 
        description: {
            pt_br: "Temos disponível no hotel uma pequena academia, para manter seu ritmo de exercícios durante sua viagem",
            en_us: "We have a small gym available at the hotel, to keep your exercise rhythm during your trip",
            es_es: "Contamos con un pequeño gimnasio disponible en el hotel, para mantener tu ritmo de ejercicio durante tu viaje"
        }
    }
];