
export default {
    selectLanguage: {
        pt_br: "Selecione um idioma",
        en_us: "Select a language",
        es_es: "Selecciona un idioma",
    },
    home: {
        pt_br: "Início",
        en_us: "Start",
        es_es: "Comienzo",
    },
    events: {
        pt_br: "Eventos",
        en_us: "Events",
        es_es: "Eventos",
    },
    specials: {
        pt_br: "Especiais",
        en_us: "Specials",
        es_es: "Especiales"
    },
    services: {
        pt_br: "Serviços",
        en_us: "Services",
        es_es: "Servicios"
    },
    gallery: {
        pt_br: "Galeria",
        en_us: "Gallery",
        es_es: "Galería"
    },
    about: {
        pt_br: "Sobre",
        en_us: "About",
        es_es: "Sobre"
    },
    accommodations: {
        pt_br: "Acomodações",
        en_us: "Accommodations",
        es_es: "Alojamientos"
    },
    internalLinks: {
        pt_br: "Links Internos",
        en_us: "Internal Links",
        es_es: "Vínculos Internos"
    },
    ourSocialNetworks: {
        pt_br: "Nossas mídias sociais",
        en_us: "Our social media",
        es_es: "Nuestras redes sociales"
    },
    ourSocialNetworksDescription: {
        pt_br: "Entre em contato conosco através de uma de nossas mídias sociais",
        en_us: "Get in touch with us through one of our social media",
        es_es: "Ponte en contacto con nosotros a través de una de nuestras redes sociales"
    },
    copyright: {
        pt_br: "© Copyright 2019. Todos os direitos reservados",
        en_us: "© Copyright 2019. All rights reserved",
        es_es: "© Copyright 2019. Todos los derechos reservados"
    },
    servicesTitle: {
        pt_br: "Confira nossos serviços",
        en_us: "Check out our services",
        es_es: "Consulta nuestros servicios"
    },
    servicesDescription: {
        pt_br: "O Binder Hotel Quality Inn está localizado no coração de Mogi das Cruzes, junto ao centro comercial, com fácil acesso a Estação de trem Mogi das Cruzes e a Rodoviária. Estamos também a 4 km do Mogi Shopping. A propriedade oferece ainda, internet wi-fi gratuita, quartos com banheiros privativos, minibar, TV a cabo. Além do buffet de café da manhã variado servido diariamente no restaurante.",
        en_us: "The Binder Hotel Quality Inn is located in the heart of Mogi das Cruzes, next to the commercial center, with easy access to the Mogi das Cruzes train station and the bus station. We are also 4 km from Mogi Shopping. The property also offers free Wi-Fi, rooms with private bathrooms, minibar, cable TV. In addition to the varied breakfast buffet served daily at the restaurant.",
        es_es: "El Binder Hotel Quality Inn está situado en el corazón de Mogi das Cruzes, junto al centro comercial, con fácil acceso a la estación de tren de Mogi das Cruzes ya la estación de autobuses. También estamos a 4 km de Mogi Shopping. La propiedad también ofrece Wi-Fi gratuito, habitaciones con baño privado, minibar, televisión por cable. Además del variado desayuno buffet que se sirve a diario en el restaurante."
    },
    galleryTitle: {
        pt_br: "Conheça um pouco de nossa estrutura que está esperando por você!",
        en_us: "Get to know a little of our structure that is waiting for you!",
        es_es: "¡Conoce un poco de nuestra estructura que te está esperando!"
    },
    galleryDescription: {
        pt_br: "",
        en_us: "",
        es_es: ""
    },
    accommodationsTitle: {
        pt_br: "Conforto e comodidade para você!",
        en_us: "Comfort and convenience for you!",
        es_es: "Comodidad y comodidad para ti!"
    },
    accommodationsDescription: {
        pt_br: "Apartamentos que possuem uma estrutura completa para garantir seu conforto, com TV a cabo, ar condicionado, wi-fi gratuito e minibar",
        en_us: "Apartments that have a complete structure to guarantee your comfort, with cable TV, air conditioning, free wi-fi and minibar",
        es_es: "Departamentos que cuentan con una estructura completa para garantizar su comodidad, con TV por cable, aire acondicionado, wi-fi gratuito y minibar"
    },
    aboutTitle: {
        pt_br: "Conheça mais sobre nós",
        en_us: "Know more about us",
        es_es: "Conozca más sobre nosotros"
    },
    aboutDescription: {
        pt_br: "O Hotel Binder foi inaugurado no ano de 1979, onde no local havia uma igreja localizada em frente ao atual largo do Rosário, onde ficava a igreja de mesmo nome.\nEstamos localizados no coração da cidade, no centro comercial de Mogi, facilitando muito o acesso a várias lojas, restaurantes, farmácias, estação de trem e a Rodoviária de Mogi das Cruzes. Estamos também a 4 km do Mogi Shopping e a 40 minutos de carro do aeroporto Internacional de Guarulhos.\nO hotel foi todo reformado e a 4 anos está sob nova gerência, possuindo um dos melhores cafés da manhã da cidade, bem diversificado e servido diariamente no restaurante, além de oferecer internet wi-fi gratuita, quartos com banheiros privativos, minibar e TV a cabo.",
        en_us: "Hotel Binder was opened in 1979, where there was a church located in front of the current Largo do Rosário, where the church of the same name was located.\nWe are located in the heart of the city, in the commercial center of Mogi, making it much easier to access to several shops, restaurants, pharmacies, train station and the Mogi das Cruzes bus station. We are also 4 km from Mogi Shopping and a 40-minute drive from Guarulhos International Airport.\nThe hotel has been completely renovated and has been under new management for 4 years, having one of the best breakfasts in town, well diversified and served daily. in the restaurant, in addition to offering free Wi-Fi, rooms with private bathrooms, minibar and cable TV",
        es_es: "El Hotel Binder fue inaugurado en 1979, donde había una iglesia ubicada frente al actual Largo do Rosário, donde estaba ubicada la iglesia del mismo nombre.\nEstamos ubicados en el corazón de la ciudad, en el centro comercial de Mogi, por lo que es mucho más fácil acceder a varias tiendas, restaurantes, farmacias, estación de tren y la estación de autobuses de Mogi das Cruzes. También estamos a 4 km de Mogi Shopping y a 40 minutos en automóvil del Aeropuerto Internacional de Guarulhos.\nEl hotel ha sido completamente renovado y ha estado bajo una nueva administración durante 4 años, con uno de los mejores desayunos de la ciudad, bien diversificado y servido. en el restaurante, además de ofrecer wifi gratuito, habitaciones con baño privado, minibar y televisión por cable"
    },
    eventsTitle: {
        pt_br: "Agende seu evento conosco!",
        en_us: "Schedule your event with us!",
        es_es: "Agenda tu evento con nosotros!"
    },
    eventsDescription: {
        pt_br: "O hotel possui duas salas de eventos com capacidade de até 120 pessoas para realizar seu evento com conforto e praticidade",
        en_us: "The hotel has two event rooms with capacity for up to 120 people to hold your event with comfort and practicality.",
        es_es: "El hotel cuenta con dos salones con capacidad hasta para 120 personas para realizar tu evento con comodidad y practicidad."
    },
    specialsTitle: {
        pt_br: "Viva momentos especiais com a gente",
        en_us: "Live special moments with us",
        es_es: "Vive momentos especiales con nosotros"
    },
    specialsDescription: {
        pt_br: "Momentos especiais pedem por cuidados especiais! Deixe que nós cuidamos de tudo para que seu dia seja ainda mais perfeito!",
        en_us: "Special moments ask for special care! Let us take care of everything to make your day even more perfect!",
        es_es: "¡Momentos especiales piden un cuidado especial! ¡Déjanos encargarnos de todo para que tu día sea aún más perfecto!"
    }
}
